<template>
  <v-container>
    <v-row>
      <div class="titre1 mx-auto">Qui est derrière cette association ?</div>
    </v-row>
    <v-row>
      <div class="trait mx-auto"></div>
    </v-row>
    <v-row>
      <div class="soustitre1 mx-auto"></div>
    </v-row>

    <v-row>
      <v-img
        src="@/assets/equipe_page/bureau.jpg"
        class="mx-auto"
        max-width="840"
      ></v-img>
    </v-row>
    <v-row>
      <div class="legende mx-auto">EQUIPE ENST'AIR PROMO 2023</div>
    </v-row>
    <div v-for="(membre, index) in membres" :key="index">
      <div class="pair" v-if="Pair(index)">
        <v-row>
          <v-col cols="4">
            <v-img
              src="@/assets/equipe_page/cercle.png"
              max-width="300"
            ></v-img>
          </v-col>
          <v-col cols="8">
            <v-row justify-space-between>
              <v-col>
                <h1 class="nom">{{ membre.nom }}</h1>
              </v-col>
              <v-col>
                <h1 class="role">{{membre.role}}</h1>
              </v-col>
            </v-row>
            <v-divider class="mb-3"></v-divider>
            <span class="presentation"> {{ membre.presentation }}</span>
          </v-col>
        </v-row>
      </div>
      <div class="pair" v-else>
        <v-row>
          <v-col cols="8">
            <v-row justify-space-between>
              <v-col>
                <h1 class="nom">{{ membre.nom }}</h1>
              </v-col>
              <v-col>
                <h1 class="role">{{membre.role}}</h1>
              </v-col>
            </v-row>
            <v-divider class="mb-3"></v-divider>
            <span class="presentation"> {{ membre.presentation }}</span>
          </v-col>
          <v-col cols="4">
            <v-img
              src="@/assets/equipe_page/cercle.png"
              max-width="300"
            ></v-img>
          </v-col>
        </v-row>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      membres: [
        {
          nom: "Quentin Proust",
          role: "Président",
          presentation: "Imagine je suis président d'ENST'Air...",
          img: ""
        },
        {
          nom: "Victor Nava",
          role: "Vice-Président",
          presentation: "En tant que vice-président de l'association, j'aide notre président dans toutes les démarches qu'il entreprend et je le remplace lorsqu'il n'est pas disponible. Mon rôle est également de contacter les entreprises pour des demandes de subventions ou pour organiser des activités (sauts en parachute, baptêmes de l'air, visites d'entreprises, ...).",
          img: ""
        },
        {
          nom: "Pierre César",
          role: "Trésorier",
          presentation: "Hey tout le monde.",
          img: ""
        },
        {
          nom: "Thibault Sagnard",
          role: "Secrétaire général",
          presentation: "Salut ! Moi c'est Thibault, je suis secrétaire général de ENST'Air ! Mon boulot c'est de prendre des notes sur ce qui est dit en réunion afin d'en garder une trace nette et précise. Je participe bien sûr également aux prises de décision de l'association.",
          img: ""
        },
        {
          nom: "Djessim Bouchou",
          role: "Respo. communication",
          presentation: "Salut ! Je suis le responsable communication de ENST'Air ! Mon but est de faire la communication des activités que l'on propose dans notre asso. Je gère aussi les réseaux sociaux et tout ce qui peut toucher à la mise en avant de l'asso : j'ai notamment conçu le site sur lequel vous vous trouvez !",
          img: ""
        }
      ],
    };
  },
  methods: {
    Pair(index) {
      return index % 2 == 0;
    },
  },
};
</script>

<style>
.titre1 {
  text-align: center;
  margin-top: 50px;
  font-weight: 500;
  font-size: 50px;
  color: #193154;
}

.trait {
  height: 1px;
  width: 300px;
  align-content: center;
  background-color: #868686;
}
.soustitre1 {
  text-align: center;
  margin-top: 10px;
  font-weight: lighter;
  font-style: italic;
  font-size: 20px;
  color: #ffffff;
  margin-bottom: 20px;
}

.legende {
  font-weight: lighter;
  font-style: italic;
  font-size: 30px;
  color: #193154;
  margin-bottom: 50px;
}

.pair, .impair {
    margin-bottom: 50px;
}

.nom {
    color: #193154;
}

.role {
  text-align: right;
  color: #193154;
  font-weight: lighter;
  font-style: italic;
}

.presentation {
    font-style: italic;
    font-size: 20px;
}
</style>